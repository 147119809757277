import * as PropTypes from "prop-types";

import CTAButton from "~/components/CTAButton";
import RichText from "~/components/RichText";

const BasicColumn = function ({ data, type = "default" }) {

  const alignmentClass = (function () {
    switch (type) {
      case "default":
        return "self-" + data.vertical_alignment;
      default:
        return "self-" + data.vertical_alignment;
    }
  })();

  return (
    <div className={`rte ${alignmentClass}`}>
      <RichText field={data.body} />
      <CTAButton className="button-small" field={data} />
    </div>
  );
};

BasicColumn.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default BasicColumn;
